let mixin = {
    data () {
        return {
            CutAncientTown: [
                // 靖港
                {
                    url: require('../../../assets/Scenicspace/ScenicspacePageBGImg.png'),
                    video: require('../../../assets/Scenicspace/ScenicspacePageVideo.mp4'),
                    VRList: 'https://www.720yun.com/vr/aa826xi8ces',
                    img: require('../../../assets/Scenicspace/JingGangFigure.png'),
                },
                // 乔口古镇
                {
                    url: require('../../../assets/AncientMaterial/ChaucerBJ.png'),
                    video: require('../../../assets/AncientMaterial/ChaucerVideo.mp4'),
                    VRList: 'https://www.720yun.com/vr/47120xikxl6',
                    img: require('../../../assets/Scenicspace/QiaoFigure.png'),
                },
                // 铜官古镇
                {
                    url: require('../../../assets/AncientMaterial/BrassBJ.png'),
                    video: require('../../../assets/AncientMaterial/BrassVideo.mp4'),
                    VRList: 'https://www.720yun.com/vr/a3025xikxen',
                    img: require('../../../assets/Scenicspace/TongFigure.png'),
                },
                // 书堂山
                {
                    url: require('../../../assets/AncientMaterial/ShutangMountainBJ.png'),
                    video: require('../../../assets/AncientMaterial/ShutangMountainVido.mp4'),
                    VRList: 'https://www.720yun.com/vr/78521xikxlr',
                    img: require('../../../assets/Scenicspace/ShuFigure.png'),
                },
                // 新康
                {
                    url: require('@/assets/AncientMaterial/NewBJ.png'),
                    video: require('@/assets/AncientMaterial/NewVideo.mp4'),
                    VRList: 'https://www.720yun.com/vr/e0b2dxikxeg',
                    img: require('@/assets/Scenicspace/XingFigure.png'),
                },
            ],
            MenuList: [
                { label: '空间主页', icon: require('../../../assets/Scenicspace/new/ZhuYe.png') },
                { label: '古镇概述', icon: require('../../../assets/Scenicspace/new/GZ.png') },
                { label: '景点游览', icon: require('../../../assets/Scenicspace/new/JD.png') },
                { label: '建筑景观', icon: require('../../../assets/Scenicspace/new/JZ.png') },
                { label: '人文故事', icon: require('../../../assets/Scenicspace/new/RW.png') },
                { label: '非物质文化遗产', icon: require('../../../assets/Scenicspace/new/FY.png') },
                { label: '倾斜摄影', icon: require('../../../assets/Scenicspace/new/SY.png') },
                { label: '红色文化', icon: require('../../../assets/Scenicspace/new/HS.png') },
            ]
        }
    }
}

export default mixin
