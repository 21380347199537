<template>
    <JDYL></JDYL>
    <div class="BigNavigation">
        <div class="shouzhi" @click="Back()">
            <i style="position: absolute;z-index: 200;display: flex;align-items: center;justify-content: center;"><img src="../../assets/VRVisit/Back.png" alt="" /></i>
            <div class="back"></div>
            <p class="item">首</p>
            <div class="Navigation "></div>
        </div>
    </div>
</template>
<script>
import JDYL from './JDYL.vue'
import mixin from './components/mixin.js'

export default {
    components: { JDYL },
    data () {
        return {
            id: 0,
            idx: 0,

        }
    },
    mounted () {
        this.id = this.$route.query.scenicId
        this.idx = +this.id - 1
    },
    methods: {
        Back () {
            this.$router.push({ path: "/" });
        },
    },
    mixins: [mixin]
}
</script>
<style lang="less" scoped>
img {
    width: 30px;
    height: 30px;
    margin: 10px 10px;
    z-index: 997;
}
* {
    padding: 0;
    margin: 0;
}
.TopBox {
    height: 100%;
    width: 100%;
    overflow: hidden;
    position: relative;
}
.Navigation {
    opacity: 0.6;
    display: flex;
    background-color: rgb(0, 0, 0);
    z-index: 994;
}
.item {
    font-size: 20px;
    margin: 3px auto;
    position: absolute;
    align-items: center;
    display: none;
    z-index: 999;
    color: #fff;
    text-align: center;
}
.back {
    z-index: 998;
    background-color: black;
    opacity: 1.5;
    position: absolute;
    display: none;
}

.item,
.Navigation,
.back {
    width: 31px;
    height: 31px;
    border: 1px none #ddd;
    border-radius: 10px;
    padding: 10px;
}
.BigNavigation div {
    z-index: 999;
    cursor: pointer;
}
.BigNavigation div:hover {
    .item,
    .new,
    .back {
        display: block;
    }
}

.BigNavigation {
    position: fixed;
    right: 19px;
    top: 345px;
}
</style>