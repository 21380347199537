<template>
    <!-- 景点游览 -->
    <div class="VRBox" v-if="list.length>0">
        <iframe :src="list[id].VRList" height="100%" width="100%" frameborder="0" allowfullscreen="true" ref="obj"></iframe>
    </div>
</template>
<script>
export default {
    data () {
        return {
            id: 0,
            list: []
        }
    },
    mounted () {

        this.id = this.$parent.idx
        this.list = this.$parent.CutAncientTown

    },
    methods: {

    }
}
</script>
<style lang="less" scoped>
.VRBox {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 0;
}
</style>